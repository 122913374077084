<template>
  <v-card flat>
    <v-card-title class="pl-0">
      <v-text-field
        v-model="search"
        append-icon="search"
        :label="$tc('global.buscar')"
        clearable
        dense
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn
        v-if="vueAddTipo"
        @click="dialog = true"
        small
        depressed
        dark
        color="buttons"
      >
        <v-icon left>add</v-icon>
        {{ $tc("global.tipo") }}
      </v-btn>
    </v-card-title>

    <v-data-table
      v-if="!loading"
      :headers="headers"
      :items="eventoTipos"
      :search="search"
      :items-per-page="10"
      :sort-by="['id']"
      class="data-tables "
      :class="{ 'data-tables__row-click': vueTipo }"
      @click:row="openEventoTipo"
    >
      <template v-slot:item.prioridade="{ item }">{{
        getPrioridade(item.prioridade)
      }}</template>
    </v-data-table>
    <Carregando v-else />

    <v-dialog v-model="dialog" max-width="400" scrollable>
      <v-card :loading="loading" :disabled="loading">
        <v-card-title class="subtitle-1 lightgray">
          {{ $tc("global.novo") + " " + $tc("global.tipo") }}
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 400px;" class="pt-6">
          <!-- Descrição -->
          <v-text-field
            v-model="eventoTipo.descricao"
            :label="$tc('global.descricao')"
            dense
            filled
            hide-details
          ></v-text-field>

          <!-- Descrição Detalhada -->
          <v-text-field
            v-model="eventoTipo.descricao_detalhada"
            :label="$tc('global.descricaodetalhada')"
            class="mt-4"
            dense
            filled
            hide-details
          ></v-text-field>

          <!-- Prioridade -->
          <v-select
            v-model="eventoTipo.prioridade"
            :items="prioridades"
            class="mt-4"
            clearable
            item-text="text"
            item-value="value"
            dense
            filled
            hide-details
            :label="$tc('global.prioridade')"
          ></v-select>

          <!-- Veiculo obrigatorio -->
          <v-card
            flat
            style="background-color: rgba(0, 0, 0, 0.06); border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important; border-bottom-left-radius: 0px !important; border-bottom-right-radius: 0px !important;"
            height="56px"
            class="pa-3 mt-4 d-flex justify-space-between align-center"
          >
            <v-subheader
              style="height: auto !important"
              class="ma-0 pa-0 body-1"
            >
              {{ $tc("veiculo.obrigatorio") }}
            </v-subheader>

            <v-switch
              class="ma-0 pa-0"
              hide-details
              :false-value="false"
              :true-value="true"
              v-model="eventoTipo.exige_veiculo"
            ></v-switch>
          </v-card>

          <!-- Estoque obrigatorio -->
          <v-card
            flat
            style="background-color: rgba(0, 0, 0, 0.06); border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important; border-bottom-left-radius: 0px !important; border-bottom-right-radius: 0px !important;"
            height="56px"
            class="pa-3 mt-4 d-flex justify-space-between align-center"
          >
            <v-subheader
              style="height: auto !important"
              class="ma-0 pa-0 body-1"
            >
              {{ $tc("eventos.estoqueobrig") }}
            </v-subheader>

            <v-switch
              class="ma-0 pa-0"
              hide-details
              :false-value="false"
              :true-value="true"
              v-model="eventoTipo.exige_estoque"
            ></v-switch>
          </v-card>

          <!-- Operacao -->
          <v-card flat class="mt-4" v-if="eventoTipo.exige_estoque">
            <v-subheader
              style="height: auto !important"
              class="ma-0 pa-0 pb-1 body-1"
            >
              {{ $tc("eventos.operacao") }}
            </v-subheader>
            <v-btn-toggle
              style="width: calc(100% / 3);"
              dense
              v-model="eventoTipo.op"
              :class="$vuetify.breakpoint.mdAndDown ? 'btn-toggle-mobile' : ''"
            >
              <v-btn small height="36" block value="E">
                {{ $tc("eventos.entrado") }}
              </v-btn>

              <v-btn small height="36" block value="A">
                {{ $tc("eventos.ambos") }}
              </v-btn>

              <v-btn small height="36" block value="S">
                {{ $tc("global.saida") }}
              </v-btn>
            </v-btn-toggle>
          </v-card>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="anchors" text small @click="dialog = false">
            {{ $tc("botoes.cancelar") }}
          </v-btn>
          <v-btn color="anchors" small depressed dark @click="createEventoTipo">
            {{ $tc("botoes.criar") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {
  getEventosTipos,
  putEventoTipo,
  postEventoTipo,
} from "@/services/api/eventos-tipos.api.js";
import { mapGetters } from "vuex";

export default {
  name: "ListaEventosTipos",
  props: {
    P_classificacao_id: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    loading: true,
    eventoTipos: null,
    atualizando: false,
    dialog: false,
    search: "",
    eventoTipo: {},
  }),
  computed: {
    ...mapGetters("Componentes", ["getAccess"]),
    vueTipo() {
      return this.getAccess("vueTipo", "visualizar");
    },
    vueAddTipo() {
      return this.getAccess("vueTipo", "adicionar");
    },
    headers() {
      return [
        {
          text: this.$tc("global.descricao"),
          value: "descricao",
        },
        {
          text: this.$tc("global.descricaodetalhada"),
          value: "descricao_detalhada",
        },
        {
          text: this.$tc("global.prioridade"),
          value: "prioridade",
        },
      ];
    },
    prioridades() {
      return [
        {
          text: this.$tc("eventos.normal"),
          value: 0,
        },
        {
          text: this.$tc("eventos.urgente"),
          value: 1,
        },
        {
          text: this.$tc("eventos.emergencial"),
          value: 2,
        },
      ];
    },
  },
  watch: {
    P_classificacao_id() {
      this.getEventoTipos();
    },
  },
  methods: {
    getPrioridade(id) {
      let prioridade = this.prioridades.find((item) => item.value === id);
      if (prioridade) return prioridade.text;
    },
    openEventoTipo(item) {
      if (this.vueTipo) {
        this.$store.commit("UPDATE_DYNAMICCRUMB", item.descricao);
        this.$router.push({
          name: "EventoTipo",
          params: { eventoTipoId: item.id },
        });
      }
    },
    getEventoTipos() {
      this.$Progress.start();
      this.loading = true;
      getEventosTipos(`?classificacao=${this.P_classificacao_id}`)
        .then((response) => {
          this.eventoTipos = response;
          this.$Progress.finish();
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    createEventoTipo() {
      this.eventoTipo.classificacao_id = this.P_classificacao_id;
      this.loading = true;
      postEventoTipo(this.eventoTipo)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success(this.$tc("msg.criadosucesso"));
            this.eventoTipo = {};
            this.getEventoTipos();
            this.dialog = false;
          }
        })
        .catch((error) => {
          console.log(error.response);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getEventoTipos();
  },
};
</script>

<style scoped lang="scss"></style>
